// let ROOT_URL ='http://staging.alphonic.net.in:6002/api/v1/c'
let ROOT_URL = "";
let IMAGE_URL = "";
/* if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'){
    console.log(1);
    // ROOT_URL ='https://jsonplaceholder.typicode.com'
}else{
    // TODO: Server API URL
    // ROOT_URL ='https://jsonplaceholder.typicode.com'
    console.log(2);
} */
console.log(process.env.REACT_APP_SERVER);
if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "https://liveapi.rajpanchhi.com/api/ad";
  IMAGE_URL = "https://dd6hbxroegie9.cloudfront.net/";
} else {
  // ROOT_URL ='http://localhost:6500/api/ad'
  // ROOT_URL ='https://liveapi.rajpanchhi.com/api/ad'
  ROOT_URL = "https://api.rajpanchhi.com/api/ad";
  IMAGE_URL = "https://dd6hbxroegie9.cloudfront.net/";
}

  // ROOT_URL ='http://localhost:6500/api/ad'
// ROOT_URL ='https://liveapi.rajpanchhi.com/api/ad'
// IMAGE_URL ='https://dd6hbxroegie9.cloudfront.net/'
export { IMAGE_URL };
export default ROOT_URL;
