import React, { useContext } from 'react';
import LeftWidget from '../components/login/leftWidget';
import RightWidget from '../components/login/rightWidget';
import LoginForm from '../components/login/loginForm';
import { UserContext } from '../context/theme';
import { ToastContainer } from 'react-toastify';


const Login = () => {
    const context = useContext(UserContext)
    return (
        <div className={`page-wrapper ${context.Theme} ${context.ThemeColor} pageLogin`}>
            <ToastContainer />
            <div className="login-wrapper d-flex">
                {/* Left side widget */}
                <div className="log__Left_sideContain">
                    <LeftWidget/>
                </div>

                {/*  Main form content */}
                <div className="Form__mainContain">
                    <LoginForm/>
                </div>
                
                {/* Right side widget */}
                <div className="log__Right_sideContain" style={{backgroundImage : `url(assets/images/login-right.png)`}}>
                    <RightWidget/>
                </div>
            </div>
        </div>
    )
}

export default Login
