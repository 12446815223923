import axios from "axios";


const UnauthAxios = axios.create();
UnauthAxios.interceptors.request.use((config) => { 
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json, text/plain, /',
        'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
        'Connection': 'keep-alive',
        'If-None-Match': 'W/"3a0-JtDo/HQOiwywytvotBMWyNkhK6Y"',
        'origin': '*',
        // "Referer": "http://api.rajpanchhi.com/",
		// "Referrer-Policy": "strict-origin-when-cross-origin",
    }
    config.headers = headers;   
    return config;
}, (error) => {
    console.log('reqErrr',error);
    return Promise.reject(error);
});

export default UnauthAxios;